<template>
  <FormInputWidget class="form-input-colours" :notRequired="notRequired" :label="label" :hideLabel="hideLabel" :hasError="hasError" :errorMsg="errorMsg">
    <Multiselect v-model="internalValue" :options="colours" :searchable="false" :allow-empty="false" :deselectLabel="null" :selectLabel="null" :selectedLabel="null" :placeholder="placeholder" :id="inputId" track-by="key" :required="!notRequired">
      <template #singleLabel="{ option }">
        <ColouredSquare class="colour" :colour="option.colour"></ColouredSquare> <strong>{{ option.label }}</strong>
      </template>
      <template #option="{ option }">
        <ColouredSquare class="colour" :colour="option.colour"></ColouredSquare> <strong>{{ option.label }}</strong>
      </template>
    </Multiselect>
  </FormInputWidget>
</template>

<script>

import formInputWidgetMixin from '@/libs/Form/components/Inputs/mixins/formInputWidget';
import formInputFieldMixin from '@/libs/Form/components/Inputs/mixins/formInputField';
import formSelectPropsMixin from '@/libs/Form/components/Inputs/mixins/formSelectProps';

export default {
  name: 'select-module-type-widget',
  mixins: [formInputWidgetMixin, formInputFieldMixin, formSelectPropsMixin],
  components: {
    Multiselect: () => import('vue-multiselect'),
    FormInputWidget: () => import('@/libs/Form/components/Inputs/Widgets/FormInputWidget'),
    ColouredSquare: () => import('./ColouredSquare'),
  },
  data () {
    return {
      colours: [
        { colour: '#855BC3', label: 'Colore 1', key: 'primo' },
        { colour: '#A56000', label: 'Colore 2', key: 'secondo' },
        { colour: '#1D79A1', label: 'Colore 3', key: 'terzo' },
        { colour: '#00ADA4', label: 'Colore 4', key: 'quarto' },
        { colour: '#EBD9CA', label: 'Colore 5', key: 'quinto' },
        { colour: '#E2D9EC', label: 'Colore 6', key: 'sesto' },
      ],
    };
  },
  computed: {
    internalValue: {
      get () {
        const option = this.colours.find(c => c.key === this.value);
        if (!option) {
          return null;
        }

        return option;
      },
      set (selectedValue) {
        this.$emit('input', selectedValue?.key || null);
      },
    },
  },
};

</script>

<style lang="scss">

@import "~vue-multiselect/dist/vue-multiselect.min.css";

.form-input-colours {
  .multiselect__single, .multiselect__option {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .colour {
    margin-right: 10px;
  }
}

</style>
